<template>
  <div>
    <div class="iframe-wrapper-plaid">
      <v-progress-circular
        v-show="loading"
        indeterminate
        color="primary"
        class="plaid-custom-loader"
        size="43"
      ></v-progress-circular>
      <PlaidLink
        ref="plaidLink"
        clientName="XL"
        :env="checkEnv"
        :link_token="token"
        :products="['auth', 'transactions']"
        :onLoad="onLoad"
        :onSuccess="onSuccess"
        :onExit="onExit"
        :onEvent="onEvent"
      />
    </div>
  </div>
</template>
<script>
import PlaidLink from "vue-plaid-link2";
import { mapActions } from "vuex";

export default {
  name: "DepositFormPlaid",
  components: { PlaidLink },
  data() {
    return {
      loading: true,
      token: null,

      newBank: null,
    };
  },
  computed: {
    checkEnv() {
      switch (this.$store.getters.environment) {
        case "localhost":
        case "dev":
        case "test":
        case "stage":
          return "sandbox";
        case "prod":
        default:
          return "production";
      }
    },
  },
  async mounted() {
    this.$emit("loading", true);
    this.token = await this.getPlaidLinkToken();

    setTimeout(async () => {
      await this.$refs.plaidLink.$el.click();
    }, 500);

    // await this.$refs.plaidLink.link_open();
  },
  beforeDestroy() {},
  methods: {
    ...mapActions({
      getPlaidLinkToken: "getPlaidLinkToken",
    }),
    onLoad() {
      console.log("onLoad");
      // this.loading = false;
    },
    onSuccess(public_token, metadata) {
      console.log("onSuccess", public_token, metadata);
      this.newBank = metadata;

      this.$emit("addBank", this.newBank);
      // this.$emit("loading", false);
    },
    onExit(err, metadata) {
      this.$emit("loading", false);
      this.$emit("exit");
    },
    onEvent(eventName, metadata) {
      console.log("onEvent", eventName, metadata);
    },
  },
};
</script>
<style scoped lang="scss">
.iframe-wrapper-plaid {
  text-align: center;
  margin: 100px 0;
}
.plaid-custom-loader {
  margin-top: -42px;
}
</style>
