import { API } from "aws-amplify";
const USER_PROFILE = `id
                verificationEmailSentTimestamp
                firstName
                buyerUnderReview
                lastName
                companyName
                companyProfile
                currentStrategy
                currentLenderDirect
                lastLogin
                numberOfFavorites
                
                address{
                    country
                    state
                    city
                    address
                    zipCode
                }
                email
                faxNumber
                officePhone
                cellPhone
                jobTitle
                aum
                buyerProfile {
                    completed
                    bankingInfo {
                      bankingInfoId
                      bankName
                      accountNumber
                      routingNumber
                      lastFour
                      token
                      category
                      federalTaxID
                      driversLicense
                      provider
                    }
                    purchasingEntity
                    purchasingEntityAddress{
                        country
                        state
                        city
                        address
                        zipCode
                    }
                    mmgpFirstName
                    mmgpLastName
                    mmgpAddress {
                        country
                        state
                        city
                        address
                        zipCode
                    }
                    pofDocument {
                        name
                        key
                        type
                        dateModified
                        dataOwnerId
                    }
                    pofSum
                    fundsApprovalDate
                    fundsApprovalExpiryDate
                    approved
                }
                sellerProfile {
                    bankingInfo {
                      bankingInfoId
                      bankName
                      accountNumber
                      routingNumber
                      lastFour
                      token
                      category
                      federalTaxID
                      driversLicense
                      provider
                    }
                    entityName,
                    dateModified
                    entityAddress {
                        country
                        state
                        city
                        address
                        zipCode
                    },
                    avatar,
                    faxNumber,
                    phoneNumber,
                    financePartner,
                    executive,
                    states,
                    title,
                    signedByFirstName,
                    signedByLastName,
                    confirmationDate
                }
                roles
                favorites
                filled {
                    user
                    seller
                    buyer
                }
                notificationsCriteria{
                assetType
                receiveNotificationsWhen
                locations
                minUpb{
                amount
                currency
                }
                maxUpb{
                amount
                currency
                }
                
                userRole,
                receivePipelineNotifications
                assetStatus,
                assetType,
                collateralType,
                collateralSubtype,
                
                }
                `;

export default {
  getProfile() {
    const query = `query getUserProfile {
            getUserProfile {
                ${USER_PROFILE}
            }
        }`;
    return API.graphql({
      query: query,
    });
  },
  getBankingInfo({ id }) {
    const query = `query getBankingInfo($id: String!) {
            getBankingInfo(id: $id) {
              bankingInfoId
              bankName
              accountNumber
              routingNumber  
              creationDate
              lastFour
              token
              category
              federalTaxID
              driversLicense
              provider
            }
        }`;
    return API.graphql({
      query: query,
      variables: {
        id,
      },
    });
  },
  addBankingInfo(variables) {
    const query = `mutation addBankingInfo(
      $token: String, 
      $category: BankingInfoCategory!, 
      $connectionType: ConnectionType!,
      $bankName:String, 
      $federalTaxID: String, 
      $driversLicense: String,
      $plaidPublicToken: String,
      $plaidAccountId: String
    ) {
        addBankingInfo(
          token:$token, 
          category:$category, 
          connectionType: $connectionType,
          bankName:$bankName, 
          federalTaxID:$federalTaxID, 
          driversLicense:$driversLicense,
          plaidPublicToken: $plaidPublicToken,
          plaidAccountId: $plaidAccountId
        ) 
        {
          bankingInfoId
          bankName
          accountNumber
          routingNumber  
          creationDate
          lastFour
          token
          category
          federalTaxID
          driversLicense
          provider
        }
      }`;

    return API.graphql({
      query: query,
      variables,
    });
  },
  editBankingInfo(variables) {
    const query = `mutation editBankingInfo(
      $id: String!, 
      $token: String!, 
      $category: BankingInfoCategory!, 
      $federalTaxID: String, 
      $driversLicense: String
    ) {
        editBankingInfo(
          id: $id,
          token: $token,
          category: $category,
          federalTaxID: $federalTaxID,
          driversLicense: $driversLicense
        ) {
          bankingInfoId
          bankName
          accountNumber
          routingNumber  
          creationDate
          lastFour
          token
          category
          federalTaxID
          driversLicense
          provider
        }
      }`;

    return API.graphql({
      query: query,
      variables,
    });
  },
  deleteBankingInfo(variables) {
    const query = `mutation deleteBankingInfo(
    $id: String!
    ) {
        deleteBankingInfo(id:$id) 
        {
                    bankingInfoId
                    bankName
                    accountNumber
                    routingNumber  
        }
      }`;

    return API.graphql({
      query: query,
      variables,
    });
  },

  updateBasicInfo({ firstName, lastName, officePhone }) {
    const mutation = `mutation updateBasicInfo (
                $firstName: String,
                $lastName: String,
                $officePhone: String
            ) {
            updateBasicInfo (
                firstName: $firstName
                lastName: $lastName
                officePhone: $officePhone
            ) { ${USER_PROFILE} }
        }`;
    const variables = {
      firstName,
      lastName,
      officePhone,
    };
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  updateAddressInfo({ address }) {
    const mutation = `mutation updateAddressInfo (
                $address: AddressInput!,
            ) {
            updateAddressInfo (
                address: $address
            ) { ${USER_PROFILE} }
        }`;
    return API.graphql({
      query: mutation,
      variables: {
        address,
      },
    });
  },
  updateCompanyInfo({ companyName, companyProfile, jobTitle, aum }) {
    const mutation = `mutation updateCompanyInfo (
                $companyName: String,
                $companyProfile: [CompanyProfile],
                $jobTitle: String,
                $aum: Float,
            ) {
            updateCompanyInfo (
                companyName: $companyName
                companyProfile: $companyProfile
                jobTitle: $jobTitle
                aum: $aum
            ) { ${USER_PROFILE} }
        }`;
    return API.graphql({
      query: mutation,
      variables: {
        companyName,
        companyProfile,
        jobTitle,
        aum,
      },
    });
  },
  updateUserNotificationsCriteriaBlock(variables) {
    const mutation = `mutation updateUserNotificationsCriteriaBlock (
               $notificationsCriteria:NotificationsCriteriaInput
            ) {
            updateUserNotificationsCriteriaBlock (
                notificationsCriteria:$notificationsCriteria
            ) { ${USER_PROFILE} }
        }`;
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  updateBuyerSellerInfo(buySellInfo) {
    const mutation = `mutation updateBuyerSellerInfo (
                $buySellInfo: BuySellInfoInput!
            ) {
            updateBuyerSellerInfo (
                buySellInfo: $buySellInfo
            ) { ${USER_PROFILE} }
        }`;
    return API.graphql({
      query: mutation,
      variables: {
        buySellInfo,
      },
    });
  },
  updateBuyerPurchaseEntityBlock({
    purchasingEntity,
    purchasingEntityAddress,
  }) {
    const mutation = `mutation updateBuyerPurchaseEntityBlock (
                $purchasingEntity: String,
                $purchasingEntityAddress: AddressInput
            ) {
            updateBuyerPurchaseEntityBlock (
                purchasingEntity: $purchasingEntity
                purchasingEntityAddress: $purchasingEntityAddress
            ) { ${USER_PROFILE} }
        }`;
    return API.graphql({
      query: mutation,
      variables: {
        purchasingEntity,
        purchasingEntityAddress,
      },
    });
  },
  updateBuyerMMGPBlock({ mmgpFirstName, mmgpLastName, mmgpAddress }) {
    const mutation = `mutation updateBuyerMMGPBlock (
                $mmgpFirstName: String,
                $mmgpLastName: String,
                $mmgpAddress: AddressInput
            ) {
            updateBuyerMMGPBlock (
                mmgpFirstName: $mmgpFirstName
                mmgpLastName: $mmgpLastName
                mmgpAddress: $mmgpAddress
            ) { ${USER_PROFILE} }
        }`;
    return API.graphql({
      query: mutation,
      variables: {
        mmgpFirstName,
        mmgpLastName,
        mmgpAddress,
      },
    });
  },
  updateBuyerAcquisitionCriteriaBlock({
    assetType,
    collateralPreferences,
    minUpb,
    maxUpb,
    lienPositions,
    loanStatus,
    propertyTypes,
    locations,
  }) {
    const mutation = `mutation updateBuyerAcquisitionCriteriaBlock (
                $assetType: [UserProfileAssetType],
                $collateralPreferences: [UserCollateralPreferences],
                $minUpb: [String],
                $maxUpb: [String]
                $lienPositions: [UserLienPositions]
                $loanStatus: [UserLoanStatus]
                $propertyTypes: [UserPropertyType]
                $locations: [String]
            ) {
            updateBuyerAcquisitionCriteriaBlock (
                assetType: $assetType
                collateralPreferences: $collateralPreferences
                minUpb: $minUpb
                maxUpb: $maxUpb
                lienPositions: $lienPositions
                loanStatus: $loanStatus
                propertyTypes: $propertyTypes
                locations: $locations
            ) { ${USER_PROFILE} }
        }`;
    return API.graphql({
      query: mutation,
      variables: {
        assetType,
        collateralPreferences,
        minUpb,
        maxUpb,
        lienPositions,
        loanStatus,
        propertyTypes,
        locations,
      },
    });
  },
  updateBuyerPoFBlock(pofDocument) {
    const mutation = `mutation updateBuyerPoFBlock (
                $pofDocument: [DocumentInput]!
            ) {
            updateBuyerPoFBlock (
                pofDocument: $pofDocument
            ) { ${USER_PROFILE} }
        }`;
    return API.graphql({
      query: mutation,
      variables: {
        pofDocument,
      },
    });
  },
  updateProfile(dataToSend) {
    const mutation = `mutation updateUserProfile (
                $firstName: String,
                $lastName: String,
                $companyName: String,
                $companyProfile: [CompanyProfile]!,
                $currentStrategy: [UserCompanyStrategy]!,
                $jobTitle: String,
                $aum: Float,
                $address: AddressInput,
                $officePhone: String,
                $cellPhone: String
            ) {
            updateUserProfile (
                firstName: $firstName
                lastName: $lastName
                companyName: $companyName
                companyProfile: $companyProfile
                currentStrategy: $currentStrategy
                jobTitle: $jobTitle
                aum: $aum
                address: $address
                officePhone: $officePhone
                cellPhone: $cellPhone
            ) { firstName lastName companyName companyProfile currentStrategy jobTitle aum address { country state city address zipCode } officePhone cellPhone }
        }`;
    const variables = {
      firstName: dataToSend.firstName,
      lastName: dataToSend.lastName,
      companyName: dataToSend.companyName,
      companyProfile: dataToSend.companyProfile,
      currentStrategy: ["Sell"],
      jobTitle: dataToSend.jobTitle,
      aum: Number.parseFloat(dataToSend.aum),
      address: {
        country: dataToSend.country,
        state: dataToSend.state,
        city: dataToSend.userAddressCity,
        address: dataToSend.userAddress,
        zipCode: dataToSend.zipCode,
      },
      officePhone: dataToSend.officePhone,
      cellPhone: dataToSend.cellPhone,
    };
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  updateSellerProfile({
    entityName,
    entityAddress,
    avatar,
    faxNumber,
    phoneNumber,
    financePartner,
    executive,
    states,
    title,
    signedByFirstName,
    signedByLastName,
  }) {
    const mutation = `mutation seller (
                $sellerProfile: SellerProfileInput!
            ) {
            updateSellerProfile (
                sellerProfile: $sellerProfile
            ) {
                sellerProfile {
                  entityName,
                  entityAddress {
                    country
                    state
                    city
                    address
                    zipCode
                  },
                  avatar,
                  faxNumber,
                  phoneNumber,
                  financePartner,
                  executive,
                  states,
                  title,
                  signedByFirstName,
                  signedByLastName,
                }
            }
        }`;
    const variables = {
      sellerProfile: {
        entityName,
        entityAddress,
        avatar,
        faxNumber,
        phoneNumber,
        financePartner,
        executive,
        states,
        title,
        signedByFirstName,
        signedByLastName,
      },
    };
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  updateLoginTime(variables) {
    const mutation = `mutation updateLoginTime ($lastLogin: AWSDateTime) {
      updateLoginTime ( lastLogin: $lastLogin) 
  {
    
    lastLogin
  
  }
}`;
    return API.graphql({
      query: mutation,
      variables,
    });
  },
  updateBuyerProfile(dataToSend) {
    const mutation = `mutation buyer (
                $buyerProfile: BuyerProfileInput!
            ) {
            updateBuyerProfile (
                buyerProfile: $buyerProfile
            ) {
                buyerProfile {
                    assetType
                    collateralPreferences
                    pofDocument {
                        name
                        key
                        type
                    }
                    loanStatus
                    propertyTypes
                    locations
                    maxUpb
                    minUpb
                    lienPositions
                    purchasingEntity
                    purchasingEntityAddress {
                        country
                        state
                        city
                        address
                        zipCode
                    }
                    mmgpFirstName
                    mmgpLastName
                    mmgpAddress {
                        country
                        state
                        city
                        address
                        zipCode
                    }
                    completed
                }
            }
        }`;
    const variables = {
      buyerProfile: {
        assetType: dataToSend.assetType,
        collateralPreferences: dataToSend.collateralPreferences,
        pofDocument: dataToSend.pofDocument,
        loanStatus: dataToSend.loanStatus,
        propertyTypes: dataToSend.propertyTypes,
        locations: dataToSend.locations,
        maxUpb: [dataToSend.upbMax],
        minUpb: [dataToSend.upbMin],
        lienPositions: dataToSend.lienPositions,
        purchasingEntity: dataToSend.purchasingEntity,
        purchasingEntityAddress: {
          country: dataToSend.purchasingEntityAddressCountry,
          state: dataToSend.purchasingEntityAddressState,
          city: dataToSend.purchasingEntityAddressCity,
          address: dataToSend.purchasingEntityAddressAddress,
          zipCode: dataToSend.purchasingEntityAddressZipCode,
        },
        mmgpFirstName: dataToSend.mmgpFirstName,
        mmgpLastName: dataToSend.mmgpLastName,
        mmgpAddress: {
          country: dataToSend.mmgpAddressCountry,
          state: dataToSend.mmgpAddressState,
          city: dataToSend.mmgpAddressCity,
          address: dataToSend.mmgpAddressAddress,
          zipCode: dataToSend.mmgpAddressZipCode,
        },
        completed: false,
      },
    };
    return API.graphql({
      query: mutation,
      variables,
    });
  },

  getUserPayments(variables) {
    const query = `query getUserPayments($userId:String! $limit:Int $nextToken:String  $marketingStatus:[String]  $paymentStatus: [PaymentStatus] ){
        getUserPayments(userId:$userId limit :$limit  nextToken:$nextToken marketingStatus:$marketingStatus paymentStatus :$paymentStatus){
          nextToken
          count
          items{
            PK
            SK
            subId
            status
            lastPaymentDate
            nextPaymentDate
            pricingPlan
            asset{
              address{
                country
                state
                city
                address
                zipCode
              }

            assetTitle
            marketingStatus
            images
            totalPayoff{
               amount
               currency
              }

            upb{
              amount
               currency
              }
           


            }
          }
        }

        }`;
    return API.graphql({
      query,
      variables,
    });
  },
  searchPayments(variables) {
    const query = ` query searchPayments(
      $PK: String
      $paymentStatus: [PaymentStatus]
      $marketingStatus: [String]
      $size: Int
      $offset: Int
      $pricingPlan: PricingPlan
      )
      {
      searchPayments(PK:$PK paymentStatus:$paymentStatus marketingStatus:$marketingStatus size:$size offset:$offset pricingPlan:$pricingPlan){
        payments{
          PK
          SK
          marketingStatus
          paymentStatus
          periodPrice
          
          upb{
            amount
          }
          address{
            country
            state
            city
            address
            zipCode
          }
          pricingPlan

          asset{
            address{
              country
              state
              city
              address
              zipCode
            }
          id
          assetStatus
          assetTitle
          marketingStatus
          images
          totalPayoff{
             amount
             currency
            }

          upb{
            amount
             currency
            }

          }

        }
        total

      }
      }`;
    return API.graphql({
      query,
      variables,
    });
  },
  stopAssetSub(variables) {
    const query = `mutation stopAssetSubscription($assetId:String!){
      stopAssetSubscription(assetId:$assetId ){
        PK
        SK
      }
    }`;
    return API.graphql({
      query,
      variables,
    });
  },
};
