<template>
  <v-dialog v-model="dialog" :attach="attach" :width="'848px'" persistent>
    <v-card v-if="modalSelectedBank" class="banks-card">
      <v-icon v-if="!hideCloseBtn" class="close-btn" @click="close(false)">
        mdi-close
      </v-icon>
      <v-card-title class="pa-0 justify-center">
        <div class="modal-title py-4">Select Another Bank</div>
      </v-card-title>
      <slot name="failedOffer"></slot>
      <v-card-text class="px-4 px-md-16 pb-0 pb-md-5">
        <template v-for="(item, idx) in getUserBanks">
          <BankingInfoSelectBankModalItem
            class="mb-4 cursor-pointer"
            :key="item.bankingInfoId"
            :item="item"
            :class="{
              'active-item':
                modalSelectedBank.bankingInfoId === item.bankingInfoId,
            }"
            @selectBank="onSelectBank(item)"
            @deleteBank="openDeleteModal(item)"
          ></BankingInfoSelectBankModalItem>
        </template>
      </v-card-text>
      <v-card-actions class="justify-center action-wrapper pb-4 pb-md-10">
        <v-btn
          v-if="!hideCloseBtn"
          width="230"
          height="40"
          class="button-second c-button cancel"
          rounded
          shaped
          outlined
          @click="close(false)"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="loading || !modalSelectedBank.bankingInfoId"
          color="#2969ff"
          width="230"
          height="40"
          class="button-second c-button confirm ml-0"
          rounded
          shaped
          @click="onConfirm"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>

    <AddNewBankIndividualDataDialog ref="addNewBankIndividualDataDialog" />

    <DeleteBankDialog
      ref="deleteBankDialog"
      @deleteBank="onDeleteBank"
    ></DeleteBankDialog>
  </v-dialog>
</template>

<script>
import BankingInfoSelectBankModalItem from "@/components/ClosingDocument/BankingInfoSelectBankModalItem.vue";
import DeleteBankDialog from "@/components/GlobalDialogs/DeleteBankDialog.vue";
import { mapActions, mapGetters } from "vuex";
import AddNewBankIndividualDataDialog from "@/components/GlobalDialogs/AddNewBankIndividualDataDialog.vue";

export default {
  name: "BankingInfoSelectBankModal",
  components: {
    AddNewBankIndividualDataDialog,
    BankingInfoSelectBankModalItem,
    DeleteBankDialog,
  },
  props: {
    offer: {
      type: Object,
      default: () => {},
    },
    chosenBank: {
      type: Object,
      default: () => ({}),
    },
    selectedBank: {
      type: Object,
      default: () => ({}),
    },
    assetsRole: {
      type: String,
      default: "",
    },
    attach: {
      type: [String, Boolean],
      default: false,
    },
    hideCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      defaultBank: null,
      modalSelectedBank: null,
      bankToDelete: null,
      loading: false,
      resolve: null,
      selectedFailedBank: null,
    };
  },
  computed: {
    ...mapGetters("UserSettings", ["getPaymentProvider", "getPaymentIframe"]),
    ...mapGetters(["isSeller", "isAdmin"]),
    getUserBanks() {
      if (
        (this.chosenBank.chosenBankingInfoId ||
          this.offer?.buyerBankingInfoId) &&
        !this.selectedFailedBank
      ) {
        return [];
      }
      if (this.$route.params.role === "seller" || this.isSeller) {
        if (Array.isArray(this.$store.state.user.sellerProfile.bankingInfo)) {
          return this.$store.state.user.sellerProfile.bankingInfo.filter(
            this.checkPayment
          );
        }
      }
      return this.$store.state.user.buyerProfile.bankingInfo.filter(
        this.checkPayment
      );
    },
  },
  watch: {
    async dialog(newVal) {
      if (newVal) {
        this.defaultBank = this.selectedBank;
        this.modalSelectedBank = this.selectedBank;

        if (
          this.chosenBank.chosenBankingInfoId ||
          this.offer?.buyerBankingInfoId
        ) {
          this.selectedFailedBank = await this.fetchBankingInfo({
            id:
              this.chosenBank.chosenBankingInfoId ||
              this.offer?.buyerBankingInfoId,
          });
        }
      } else {
        this.defaultBank = null;
        this.modalSelectedBank = null;
      }
    },
  },
  methods: {
    ...mapActions(["editBankingInfo", "fetchBankingInfo"]),
    checkPayment(item) {
      if (this.selectedFailedBank) {
        if (
          this.selectedFailedBank.provider === "vopay" &&
          item.provider === "vopay"
        ) {
          return true;
        } else if (
          this.selectedFailedBank.provider === "plaid" &&
          item.provider === "plaid"
        ) {
          return true;
        }
      } else {
        if (
          this.getPaymentProvider === "vopay" &&
          this.getPaymentIframe === "vopay" &&
          item.provider === "vopay"
        ) {
          return true;
        } else if (
          this.getPaymentProvider === "plaid" &&
          this.getPaymentIframe === "plaid" &&
          item.provider === "plaid"
        ) {
          return true;
        } else if (
          this.getPaymentProvider === "vopay" &&
          this.getPaymentIframe === "plaid"
        ) {
          return true;
        }
      }
      return false;
    },
    async onConfirm() {
      let bank = {};
      const { federalTaxID, driversLicense, bankingInfoId, token } =
        this.modalSelectedBank;
      if (federalTaxID || driversLicense) {
        bank = this.modalSelectedBank;
      } else {
        const bankDataResult =
          await this.$refs.addNewBankIndividualDataDialog.open();

        if (bankDataResult) {
          const params = {
            ...bankDataResult,
            id: bankingInfoId,
            token: token,
          };

          this.loading = true;
          bank = await this.editBankingInfo(params);
          this.loading = false;
        }
      }

      if (bank) {
        this.$emit("selectBank", bank);
        this.close(bank);
      }
    },
    openDeleteModal(bank) {
      this.bankToDelete = bank;
      this.$refs.deleteBankDialog.open(bank);
    },
    onDeleteBank() {
      this.$emit("selectBank", this.getUserBanks[0]);
      this.bankToDelete = null;
    },
    onSelectBank(item) {
      this.modalSelectedBank = item;
    },
    open() {
      this.dialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close(v) {
      this.dialog = false;
      this.resolve(v);
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%; /* 30.4px */
  letter-spacing: 0.444px;
}
.active-item {
  border-radius: 7px;
  border: 1px solid #2969ff;
  background: #f2f6ff;
  box-shadow: 2px 2px 15px 0px rgba(173, 173, 173, 0.25);
}
.banks-card {
  position: relative;
}
.close-btn {
  cursor: pointer !important;
  position: absolute;
  right: 20px;
  top: 20px;
}
::v-deep {
  .v-btn:not(.v-btn--round, .v-btn--text).v-size--default {
    min-height: auto;
  }
}
.cancel {
  border: 1px solid #2969ff !important;
  ::v-deep .v-btn__content {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    color: #2969ff;
  }
}
.confirm {
  background: #2969ff;
  ::v-deep .v-btn__content {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    color: #ffffff;
  }
}
.action-wrapper {
  column-gap: 32px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .action-wrapper {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between !important;
    column-gap: unset;
  }
  .button-second {
    width: auto !important;
  }
}
</style>
